import React, { FC, useEffect } from 'react';
import { AppContext, AppRecord, NotesResponse } from '../../types';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styled from 'styled-components';
import { getApiUrl, menuItemClicked } from '../../utils/utils';
import {
  APP_APPROVED,
  APP_DELETED,
  APP_DENIED,
  UPDATE_NOTES,
} from '../../constants';
import { red } from '@material-ui/core/colors';
import { useHistory } from 'react-router-dom';

const ControlsGrid = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 120px 400px 1fr;
  grid-template-rows: 1fr 1fr;
`;

const NotesWrapper = styled.div`
  display: grid;
  grid-template-rows: 25px 1fr;
  grid-row: 1 / 3;
  grid-column: 2/3;
  justify-self: center;
`;

const StatusBar = styled.div`
  background-color: ${({ color }) => color};
  grid-row: 2/3;
  grid-column: 3/4;
  margin: 10px;
  margin-left: 0;
  margin-right: 75px;
  border-radius: 5px;
  color: white;
`;
const ReviewControls: FC<AppContext> = ({
  selected,
  setSelected,
  applications,
  setApplications,
}) => {
  const history = useHistory();
  const goBack = (e: any) => {
    setSelected({} as AppRecord);
  };

  useEffect(() => {
    if (Object.keys(selected || {}).length === 0) {
      history.push('/applicationTable');
    }
  }, [selected]);
  const notesChanges = (e: any) => {
    const newItem = { ...selected, Notes: e.target.value };
    setSelected(newItem);
  };

  const updateNotes = (e: any) => {
    const appId = e.currentTarget.dataset.appid;
    fetch(getApiUrl(UPDATE_NOTES), {
      method: 'post',
      body: JSON.stringify({ appId: appId, notes: e.target.value }),
    })
      .then((response) => response.json())
      .then((data: NotesResponse) => {
        const newApps = applications?.map((item: AppRecord) => {
          if (appId === item.AppId) {
            return { ...item, Notes: data.notes };
          }
          return item;
        });
        const newArray = [...newApps!];
        setApplications(newArray);
      })
      .catch((e) => console.log(e));
    console.log(e.target.value);
  };

  const deleteClick = (e: any) => {
    if (selected && applications) {
      menuItemClicked(
        e,
        APP_DELETED,
        selected,
        setSelected,
        applications,
        setApplications,
      );
      history.push('/applicationTable');
    }
  };
  const approvedClick = (e: any) => {
    if (selected && applications) {
      menuItemClicked(
        e,
        APP_APPROVED,
        selected,
        setSelected,
        applications,
        setApplications,
      );
    }
  };
  const rejectedClick = (e: any) => {
    if (selected && applications) {
      menuItemClicked(
        e,
        APP_DENIED,
        selected,
        setSelected,
        applications,
        setApplications,
      );
    }
  };
  const color = selected?.Status === APP_APPROVED ? 'green' : 'red';
  const status = selected?.Status === APP_APPROVED ? 'Approved' : 'Denied';
  return (
    <ControlsGrid>
      <Button
        variant='contained'
        onClick={goBack}
        startIcon={<ArrowBackIcon />}>
        Back
      </Button>
      <NotesWrapper>
        <span>Notes</span>
        <textarea
          style={{ width: '240px' }}
          value={selected?.Notes}
          onBlur={updateNotes}
          onChange={notesChanges}
          data-appid={selected?.AppId}
        />
      </NotesWrapper>
      <Stack direction='row' spacing={2}>
        <Button
          variant='contained'
          startIcon={<DeleteIcon />}
          onClick={deleteClick}
          disabled={
            selected?.Status === APP_APPROVED || selected?.Status === APP_DENIED
          }>
          Delete
        </Button>
        <Button variant='contained' color='success' onClick={approvedClick}>
          Approve
        </Button>
        <Button variant='contained' color='error' onClick={rejectedClick}>
          Deny
        </Button>
      </Stack>
      {(selected?.Status === APP_APPROVED ||
        selected?.Status === APP_DENIED) && (
        <StatusBar color={color}>{status}</StatusBar>
      )}
    </ControlsGrid>
  );
};

export default ReviewControls;
