import React, { FC } from 'react';
import _ from 'lodash';
import {
  Field,
  Layout,
  SectionContainer,
  StaticField,
} from '../../common/common.style';
import { AppContext } from '../../../types';

const GeneralQuestions: FC<AppContext> = ({ selected, namespace }) => {
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>Additional Questions</div>
      <Layout>
        <Field>
          <div>Filed petition for bankruptcy:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.filedPetitioinForBankruptcy`)
                ? 'Yes'
                : 'No'}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Have you been evicted?:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.haveBeenEvicted`) ? 'Yes' : 'No'}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Have you refused to pay rent?:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.refuseToPayRent`) ? 'Yes' : 'No'}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Are you smoking?:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.smoking`) ? 'Yes' : 'No'}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Do you have any legal judgement pending or against you?:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.judgementPending`) ? 'Yes' : 'No'}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Do you posses any fire arms?:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.firearms`) ? 'Yes' : 'No'}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Do you have any pets?:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.anyPets`) ? 'Yes' : 'No'}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Type of fire arms?:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.typeOfFirearm`, '')}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>What is the pet type?:</div>
          <div>
            <StaticField>{_.get(selected, `Json.typeOfPet`, '')}</StaticField>
          </div>
        </Field>
        <Field>
          <div>Additional Notes:</div>
          <StaticField>
            <div>{_.get(selected, `Json.comments`, '')}</div>
          </StaticField>
        </Field>
      </Layout>
    </SectionContainer>
  );
};

export default GeneralQuestions;
