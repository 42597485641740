import React, { FC, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AppRecord } from '../../types';
import Routes from '../Routes/Routes';
import './App.css';

const App: FC = () => {
  const [applications, setApplications] = useState<AppRecord[]>([]);
  const [selected, setSelected] = useState<AppRecord>({} as AppRecord);

  return (
    <div className='App'>
      <BrowserRouter>
        <Routes
          applications={applications}
          setApplications={setApplications}
          selected={selected}
          setSelected={setSelected}
        />
      </BrowserRouter>
    </div>
  );
};

export default App;
