import React, { FC, useEffect } from 'react';
import { AppContext, AppRecord, IOccupant } from '../../../types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {
  Field,
  Layout,
  OccupantRecord,
  SectionContainer,
  StaticField,
} from '../../common/common.style';

const Occupants: FC<AppContext> = ({ selected, setSelected }) => {
  useEffect(() => {
    if (!(selected && selected.Json && selected.Json.Occupants)) {
      //Occupants
      const newData: AppRecord = {
        ...selected!,
        Json: { ...selected!.Json, Occupants: [] },
      };
      setSelected(newData);
    }
  }, [selected, setSelected]);

  //https://github.com/FortAwesome/Font-Awesome/tree/master/js-packages/%40fortawesome/free-regular-svg-icons
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>Occupants</div>
      <Layout>
        {((selected && selected.Json && selected.Json.Occupants) || []).map(
          (occupant: IOccupant, index: number) => (
            <OccupantRecord key={index}>
              {/* <CloseIcon onClick={() => removeRecord()}>
                <FontAwesomeIcon icon={faWindowClose} />
              </CloseIcon> */}
              <Field>
                <div>Name:</div>
                <div>
                  <StaticField>{occupant.Name}</StaticField>
                </div>
              </Field>
              <Field>
                <div>Relationship:</div>
                <div>
                  <StaticField>{occupant.Relationship}</StaticField>
                </div>
              </Field>
              <Field>
                <div>Age:</div>
                <div>
                  <StaticField>{occupant.Age}</StaticField>
                </div>
              </Field>
            </OccupantRecord>
          ),
        )}
      </Layout>
    </SectionContainer>
  );
};

export default Occupants;
