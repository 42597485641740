import { APP_DELETED, ROOT_URL, UPDATE_STATUS } from '../constants';
import { AppRecord, StatusResponse } from '../types';

export const getApiUrl = (api: string, queryString?: string) => {
  if (!queryString) {
    queryString = '';
  } else {
    queryString = '&' + queryString;
  }
  return `${ROOT_URL}${api}.php?cb=${new Date().getTime()}${queryString}`;
};
const deleteRecord = (
  apps: AppRecord[],
  selected: AppRecord,
  setSelected: any,
  setApplications: any,
  j: StatusResponse,
) => {
  const revisedApps = apps.reduce((arr: AppRecord[], app: AppRecord) => {
    if (selected.AppId !== app.AppId && j.statusStr === 'success') {
      arr.push(app);
    }
    return arr;
  }, []);
  setApplications(revisedApps);
};
const updateStatus = (
  apps: AppRecord[],
  selected: AppRecord,
  setSelected: any,
  setApplications: any,
  j: StatusResponse,
) => {
  const revisedApps = apps.map((app: AppRecord) => {
    if (selected.AppId === app.AppId && j.statusStr === 'success') {
      const newApp = {
        ...app,
        Status: parseInt(j.status),
        PopupOpened: false,
      };
      setSelected(newApp);
      return newApp;
    }
    return app;
  });
  setApplications(revisedApps);
};
export const menuItemClicked = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  status: number,
  selected: AppRecord,
  setSelected: any,
  apps: AppRecord[],
  setApplications: any,
) => {
  e.preventDefault();
  e.stopPropagation();
  const appId = selected ? selected.AppId : -1;
  //https://app.dkassociates.us/api/updateStatus.php?a=1&appId=67&status=2
  fetch(getApiUrl(UPDATE_STATUS, `appId=${appId}&status=${status}`))
    .then((response) => response.json())
    .then((j: StatusResponse) => {
      console.log(`j:${JSON.stringify(j)}`);
      if (parseInt(j.status) === APP_DELETED) {
        deleteRecord(apps, selected, setSelected, setApplications, j);
      } else {
        updateStatus(apps, selected, setSelected, setApplications, j);
      }
    })
    .catch((e: any) => console.log(e));

  const newApps = apps.map((record: AppRecord) => {
    return { ...record, PopupOpened: false };
  });
  setApplications(newApps);
};
