import React, { FC } from 'react';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import Address from '../../common/Address';
import {
  AddressWrapper,
  Field,
  Layout,
  SectionContainer,
  StaticField,
} from '../../common/common.style';
import { AppContext } from '../../../types';

const References: FC<AppContext> = ({ selected, namespace }) => {
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>{`${namespace} Reference`}</div>
      <Layout>
        <Field>
          <div>Name:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.${namespace}Name`, '')}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Phone:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.${namespace}Phone`, '')}
            </StaticField>
          </div>
        </Field>
        <Field>
          <div>Length of relationship:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.${namespace}LengthofRelationship`, '')}
            </StaticField>
          </div>
        </Field>
        <AddressWrapper>
          <Address namespace={namespace} selected={selected} />
        </AddressWrapper>
      </Layout>
    </SectionContainer>
  );
};

export default References;
