import React, { FC } from 'react';
import { Route } from 'react-router-dom';
import {
  APPLICATIONS_TABLE,
  REVIEW_APPLICATION,
  UNAUTHORIZED,
} from '../../constants';
import { AppContext } from '../../types';
import ApplicationsTable from '../ApplicationsTable/ApplicationsTable';
import Landing from '../Landing/Landing';
import ReviewApplication from '../ReviewApplication/ReviewApplication';
import Unauthorized from '../Unauthorized/Unauthorized';

const Routes: FC<AppContext> = (props) => (
  <>
    {/*  */}
    <Route path='/' render={() => <Landing />} />
    <Route path={UNAUTHORIZED} render={() => <Unauthorized />} />
    <Route
      path={APPLICATIONS_TABLE}
      render={() => <ApplicationsTable {...props} />}
    />
    <Route
      path={REVIEW_APPLICATION}
      render={() => <ReviewApplication {...props} />}
    />
  </>
);

export default Routes;
