import React, { FC } from 'react';
import _ from 'lodash';
import {
  AddressLayout,
  Field,
  FieldLeft,
  StaticField,
  WideField1,
  WideField2,
} from './common.style';
import { AppContext } from '../../types';

const Address: FC<AppContext> = ({ namespace, selected }) => {
  return (
    <AddressLayout>
      <WideField1>
        <div>Address1:</div>
        <div>
          <StaticField>
            {_.get(selected, `Json.${namespace}.Address.Address1`, '')}
          </StaticField>
        </div>
      </WideField1>
      <WideField2>
        <div>Address2:</div>
        <div>
          <StaticField>
            {_.get(selected, `Json.${namespace}.Address.Address2`, '')}
          </StaticField>
        </div>
      </WideField2>
      <Field>
        <FieldLeft>
          <div>City:</div>
          <div>
            <StaticField>
              {_.get(selected, `Json.${namespace}.Address.City`, '')}
            </StaticField>
          </div>
        </FieldLeft>
      </Field>
      <Field>
        <FieldLeft>
          <div>State:</div>
          <StaticField>
            {_.get(selected, `Json.${namespace}.Address.State`, '')}
          </StaticField>
        </FieldLeft>
      </Field>
      <Field>
        <FieldLeft>
          <div>Zip code:</div>
          <StaticField>
            {_.get(selected, `Json.${namespace}.Address.Zipcode`, '')}
          </StaticField>
        </FieldLeft>
      </Field>
    </AddressLayout>
  );
};

export default Address;
