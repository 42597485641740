import { SendToMobileTwoTone } from '@mui/icons-material';
import _ from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  APP_APPROVED,
  APP_DENIED,
  APP_PRESTINE,
  APP_READ,
  GET_ALL_APPLICATIONS,
  REVIEW_APPLICATION,
  UNAUTHORIZED,
  UPDATE_STATUS,
} from '../../constants';
import {
  AppContext,
  AppRecord,
  AppRecordRaw,
  StatusResponse,
} from '../../types';
import { getApiUrl } from '../../utils/utils';
import PopupMenu from '../PopupMenu/PopupMenu';

const ApplicationsTable: FC<AppContext> = ({
  applications,
  setApplications,
  selected,
  setSelected,
}) => {
  const [appId, setAppId] = useState();
  const [apps, setApps] = useState<AppRecord[]>(
    applications || ([] as AppRecord[]),
  );
  useEffect(() => {
    fetch(getApiUrl(GET_ALL_APPLICATIONS))
      .then((response) => response.json())
      .then((j: AppRecordRaw[]) => {
        if (parseInt(_.get(j, '[0].Status')) === 504) {
          history.push(UNAUTHORIZED);
          return;
        }
        const r: AppRecord[] = j.map((item: AppRecordRaw) => {
          const json = JSON.parse(item.Json);
          json.DateOfBirth = json.DateOfBirth;
          const ni: AppRecord = {
            AppId: item.AppId,
            Screen: json.Screen,
            Email: item.Email,
            Created: new Date(item.Created),
            Verified: parseInt(item.Verified) === 1,
            VerifiedTime: new Date(item.VerifiedTime),
            Submitted: parseInt(item.Submitted) === 1,
            Json: json,
            PopupOpened: false,
            Notes: item.Notes || '',
            Status: parseInt(item.Status),
          };
          return ni;
        });
        setApplications(r);
      });
  }, []);
  useEffect(() => {
    setApps(applications!);
  }, [applications]);

  useEffect(() => {
    if (selected && selected.Status === APP_PRESTINE) {
      fetch(getApiUrl(UPDATE_STATUS, `appId=${appId}&status=${APP_READ}`))
        .then((response) => response.json())
        .then((j: StatusResponse) => {
          const revisedApps = apps.map((app: AppRecord) => {
            if (app.AppId === appId && j.statusStr === 'success') {
              let newApp = { ...app };
              if (app.Status === APP_PRESTINE) {
                newApp = { ...app, Status: parseInt(j.status) };
              }
              return newApp;
            }
            return app;
          });
          setApplications(revisedApps);
          setApps(revisedApps);
          history.push(REVIEW_APPLICATION);
        })
        .catch((e: any) => console.log(e));
    } else if (Object.keys(selected || {}).length > 0) {
      history.push(REVIEW_APPLICATION);
    }
    //getDOB();
  }, [selected]);

  useEffect(() => {
    const clicked = apps.find((a: AppRecord) => a.AppId === appId);
    if (clicked && clicked.Json) {
      // } else if (clicked && clicked.Json) {
      setSelected(clicked);
    }
  }, [appId]);
  const history = useHistory();

  const elipClicked: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setAppId(_.get(e, 'currentTarget.dataset.appid', 0));
    const revisedApps = apps.map((app: AppRecord) => {
      let newApp;
      if (app.AppId === appId) {
        newApp = { ...app, PopupOpened: true };
        return newApp;
      }
      newApp = { ...app, PopupOpened: false };
      return newApp;
    });
    setApplications(revisedApps);
    setApps(revisedApps);
    return false;
  };
  const onClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    setAppId(_.get(e, 'currentTarget.dataset.appid', 0));
  };
  return (
    <>
      <div className='app-table'>
        <div className='app-header app-row'>
          <div className='header-cell'>Full Name</div>
          <div className='header-cell'>Phone Number</div>
          <div className='header-cell'>Email</div>
          <div className='header-cell'>Lic. State</div>
          <div className='header-cell'>Time Received</div>
          <div className='header-cell'></div>
        </div>
        {apps.map((app: AppRecord, index: number) => {
          const zebraClass = index % 2 === 0 ? 'row-even' : 'row-odd';
          const reviewedClass = app.Status === APP_READ ? '' : 'unopened';
          const appApproved = app.Status === APP_APPROVED ? 'app-approved' : '';
          const appDenied = app.Status === APP_DENIED ? 'app-denied' : '';
          const {
            Json: { FullName, PhoneNumber, Email, LicenseState },
          } = app;
          return (
            <div
              key={index}
              className={`app-row ${zebraClass} ${reviewedClass} ${appApproved} ${appDenied}`}
              onClick={onClick}
              data-appid={app.AppId}>
              <div className='row-cell'>{FullName}</div>
              <div className='row-cell'>{PhoneNumber}</div>
              <div className='row-cell'>{Email}</div>
              <div className='row-cell'>{LicenseState}</div>
              <div className='row-cell'>
                {app.Created.toISOString().split('T')[0]}
              </div>
              <div
                className='row-cell'
                onContextMenu={elipClicked}
                data-appid={app.AppId}>
                ...
              </div>
              {app.PopupOpened && (
                <PopupMenu
                  appId={app.AppId}
                  applications={applications}
                  setApplications={setApplications}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default ApplicationsTable;
