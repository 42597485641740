import styled, { css } from 'styled-components';
export const SectionContainer = styled.div`
  display: grid;
  justify-content: center;
`;

export const MainArea = styled.div`
  height: 80vh;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
`;
export const RequiredField = styled.div<{ required: boolean }>`
  border: ${(props) => {
    return props.required ? '3px solid red' : 'none';
  }};
  width: max-content;
  border-radius: 5px;
`;

const layoutBaseStyles = css`
  display: grid;
  grid-gap: 6px 25px;
  width: 800px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    width: 100%;
  }
`;

export const Layout = styled.div`
  ${layoutBaseStyles};
  grid-template-columns: 1fr 1fr;
`;

export const RentalLayout = styled.div`
  ${layoutBaseStyles};
  grid-template-columns: 1fr;
`;

export const Field = styled.div`
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  & > div {
    min-width: 179px;
    /* display: flex; */
  }
`;

export const FieldBorder = styled.div`
  border: 1px solid white;
  margin: 5px;
  padding: 3px;
  padding-left: 12px;
  width: 100%;
  border-radius: 7px;
`;

export const FieldLeft = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
  & > div {
    width: fit-content;
  }
  @media (min-width: 680px) {
    justify-content: flex-start;
    display: flex;
  }
`;

export const SectionHeader = styled.div`
  font-size: 23px;
  background-color: #aeaeae;
`;

export const AddressWrapper = styled.div`
  grid-column: 1/3;
  @media (max-width: 680px) {
    grid-column: 1/2;
  }
`;

export const AddressLayout = styled.div`
  display: grid;
  grid-gap: 6px 25px;
  grid-template-columns: 1fr;
  @media (min-width: 680px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
`;

const baseInputStyles = css`
  font-size: 14px;
  display: flex;
  justify-content: left;
  @media (min-width: 680px) {
    & > * input {
      width: 255px;
      margin-left: 3px;
      grid-column: 1 / 3;
    }
  }
`;
export const WideField1 = styled.div`
  ${baseInputStyles}
  @media (min-width: 680px) {
    grid-row: 1 / 2;
  }
`;
export const WideField2 = styled.div`
  ${baseInputStyles}
  @media (min-width: 680px) {
    grid-row: 2 / 3;
  }
`;

export const StateField = styled.div`
  font-size: 14px;

  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  & > div {
    width: fit-content;
  }
  @media (min-width: 600px) {
    justify-content: flex-end;
    display: grid;
    & > * input {
      width: 37px;
    }
  }
`;

export const OccupantRecord = styled.div`
  margin: 8px;
`;

export const LoaderImage = styled.img`
  height: 25px;
  margin-left: 7px;
`;

export const ImgWrapper = styled.div`
  width: 30px;
  height: 30px;
`;

export const StandardButton = styled.div`
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  display: flex;
  border-radius: 6px;
  width: fit-content;
  margin: auto;
  margin-top: 3px;
  padding: 4px;
  cursor: pointer;
  &:active {
    border-top: 2px solid grey;
    border-left: 2px solid grey;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
  }
`;

export const ButtonBox = styled.div`
  width: 180px;
  height: 30px;
`;

export const CloseIcon = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: flex-end;
  padding-right: 3px;
  height: 26px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 47px;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 2px solid white;
  padding-top: 13px;
`;

export const VerifyAddressBlock = styled.div`
  max-width: 600px;
  margin: auto;
  font-size: 1.1rem;
  text-align: start;
  padding-top: 40px;
`;

export const ContinueWrapper = styled.div`
  padding-top: 20px;
`;

export const EmailWrapper = styled.div`
  position: relative;
`;

export const EmailBlocker = styled.div`
  width: 176px;
  height: 20px;
  position: absolute;
  top: 0px;
  background-color: rgba(25, 25, 25, 0.4);
`;

export const ErrorMessage = styled.div`
  color: red;
`;

export const SuccessMessage = styled.div`
  display: grid;
  color: green;
  font-weight: bold;
`;

export const GradientBackground = styled.div`
  background-image: linear-gradient(to bottom right, rgb(0, 121, 142), yellow);
  color: white;
`;

export const SmallSwitchLabel = styled.div`
  .MuiFormControlLabel-label {
    font-size: 0.7rem !important;
    color: yellow;
  }
`;

export const SubmitButtonCover = styled.div`
  width: 300px;
  height: 34px;
  left: calc(50%-20px);
  position: absolute;
  background-color: rgba(144, 144, 144, 0.5);
`;

export const StaticField = styled.div`
  font-size: 12px;
  color: green;
  font-weight: bold;
  border: 1px solid gray;
  width: 200px !important;
  min-height: 18px;
  height: auto;
  border-radius: 5px;
`;
