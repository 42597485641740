import React, { FC } from 'react';
import _ from 'lodash';
import 'react-datepicker/dist/react-datepicker.css';
import Address from '../../common/Address';
import {
  AddressWrapper,
  Field,
  FieldLeft,
  Layout,
  SectionContainer,
} from '../../common/common.style';
import { StaticField } from '../../common/common.style';
import { AppContext } from '../../../types';

const RentalData: FC<AppContext> = ({ selected, namespace }) => {
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>{namespace} Rental Data</div>
      <Layout>
        <AddressWrapper>
          <Address namespace={`${namespace}Landlord`} selected={selected} />
        </AddressWrapper>

        <Field>
          <FieldLeft>
            <div>{namespace} Rent:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Landlord.Rent`, '')}
              </StaticField>
            </div>
          </FieldLeft>
        </Field>

        <Field>
          <FieldLeft>
            <div>{namespace} Landlord Name:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Landlord.Name`, '')}
              </StaticField>
            </div>
          </FieldLeft>
        </Field>

        <Field>
          <FieldLeft>
            <div>Lease Start Date:</div>
            <div>
              <StaticField>
                {_.get(
                  selected,
                  `Json.${namespace}Landlord.LeaseStartDate`,
                  '',
                )}
              </StaticField>
            </div>
          </FieldLeft>
        </Field>

        <Field>
          <FieldLeft>
            <div>{namespace} Landlord Phone:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Landlord.Phone`, '')}
              </StaticField>
            </div>
          </FieldLeft>
        </Field>
        <Field>
          <FieldLeft>
            <div>Lease Term (years):</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Landlord.LeaseLong`, '')}
              </StaticField>
            </div>
          </FieldLeft>
        </Field>
      </Layout>
    </SectionContainer>
  );
};

export default RentalData;
