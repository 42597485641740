import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { AppContext } from '../../types';
import GeneralQuestions from './GeneralQuestions/GeneralQuestions';
import Occupants from './Occupants/Occupants';
import Occupation from './Occupation/Occupation';
import PersonalData from './PersonalData/PersonalData';
import References from './Reference/Reference';
import RentalData from './RentalData/RentalData';
import ReviewControls from './ReviewControls';
import './ReviewApplication.css';
import { GET_LICENSE } from '../../constants';
import { getApiUrl } from '../../utils/utils';
import MoreIncomes from './MoreIncomes/MoreIncomes';

const ReviewWrapper = styled.div`
  display: grid;
  grid-template-rows: 100px 700px;
  height: 100%;
  overflow: hidden;
  width: 930px;
  margin: auto;
`;

const ReviewContent = styled.div`
  overflow: scroll;
  border: 1px solid gray;
`;
const ReviewApplication: FC<AppContext> = (props) => {
  const [showOccupants, setShowOccupants] = useState<boolean>(false);
  const [showAdditionlIncome, setShowAdditionlIncome] =
    useState<boolean>(false);
  const [front, setFront] = useState<string>('');
  const [back, setBack] = useState<string>('');
  const [imageVal, setImageVal] = useState<boolean>(false);
  const [img1Full, setImg1Full] = useState<boolean>(false);
  const [img2Full, setImg2Full] = useState<boolean>(false);
  useEffect(() => {
    const occupants = _.get(props, 'selected.Json.Occupants');
    if (occupants) {
      setShowOccupants(occupants.length > 0);
    }
    const moreIncomes = _.get(props, 'selected.Json.MoreIncomes');
    if (moreIncomes) {
      setShowAdditionlIncome(moreIncomes.length > 0);
    }
    const appId = _.get(props, 'selected.AppId');
    fetch(getApiUrl(GET_LICENSE, `appId=${appId}`))
      .then((response) => response.json())
      .then((data: any) => {
        setFront(data.FrontPic);
        setBack(data.BackPic);
      });
      if(props.selected){
        setImageVal(props.selected.Screen !== "desktop")
      }
      
  }, [props, props.selected]);
  const onImageClick = (num: number) => {
    if (num === 1) {
      const isFull = img1Full;
      setImg1Full(!isFull);
    } else {
      const isFull2 = img2Full;
      setImg2Full(!isFull2);
    }
  };
  
  const imgClass = imageVal ? 'img' : 'img1';
  const imgOneZoom = img1Full ? 'imgZoom' : '';
  const imgTwoZoom = img2Full ? 'imgZoom' : '';
  return (
    <ReviewWrapper>
      <ReviewControls {...props} />
      <ReviewContent>
        {/* <input
          type='checkbox'
          checked={imageVal}
          // @ts-ignore
          onChange={(e) => setImageVal(e.target.checked)}
        /> */}
        <div className='images'>
          <img
            className={`${imgOneZoom} ${imgClass} `}
            src={front}
            alt='front License'
            onClick={(e) => onImageClick(1)}
          />
          <img
            className={`${imgTwoZoom} ${imgClass}`}
            src={back}
            alt='back License'
            onClick={(e) => onImageClick(2)}
          />
        </div>
        <PersonalData {...props} />
        <RentalData namespace='Current' {...props} />
        {showOccupants && <Occupants {...props} />}
        {showAdditionlIncome && <MoreIncomes {...props} />}
        <RentalData namespace='Previous' {...props} />
        <Occupation namespace='Current' {...props} />
        <Occupation namespace='Previous' {...props} />
        <References namespace='Bank' {...props} />
        <References namespace='Personal' {...props} />
        <References namespace='Nearest' {...props} />
        <GeneralQuestions {...props} />
      </ReviewContent>
    </ReviewWrapper>
  );
};

export default ReviewApplication;
/*

                '/PersonalData',
                    '/RentalData',
                 '/Occupation',
                  '/References',
                      BankReference?: IReference;
                      PersonalReference?: IReference;
                      NearestRelative?
  '/GeneralQuestions',
  */
