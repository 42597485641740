import React, { FC } from 'react';
import _ from 'lodash';
import Address from '../../common/Address';
import {
  AddressWrapper,
  Field,
  Layout,
  SectionContainer,
  StaticField,
} from '../../common/common.style';
import { AppContext } from '../../../types';

const Occupation: FC<AppContext> = ({ selected, namespace }) => {
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>{`${namespace} Employer`}</div>
      <AddressWrapper>
        <Layout>
          <Field>
            <div>Occupation:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.Occupation`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>Employer:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.EmployerName`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>Self Employed DBA:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.Dba`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>Business Phone:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.BusinessPhone`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>Type of business:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.TypeOfBusiness`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>Position Held:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.PositionHeld`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>Name and title of supervisor:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.Supervisor`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>How Long Years:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.HowLongYears`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>Monthly Gross Income:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.MonthlyGrossIncome`, '')}
              </StaticField>
            </div>
          </Field>
          <Field>
            <div>How Long Months:</div>
            <div>
              <StaticField>
                {_.get(selected, `Json.${namespace}Job.HowLongMonths`, '')}
              </StaticField>
            </div>
          </Field>
        </Layout>
      </AddressWrapper>
      <Address namespace={`${namespace}Job`} selected={selected} />
    </SectionContainer>
  );
};

export default Occupation;
