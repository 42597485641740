import React, { FC, useEffect, useState } from 'react';
import {
  APP_APPROVED,
  APP_DELETED,
  APP_DENIED,
  APP_PRESTINE,
  APP_READ,
  UPDATE_STATUS,
} from '../../constants';
import { AppContext, AppRecord, StatusResponse } from '../../types';
import { getApiUrl, menuItemClicked } from '../../utils/utils';
import './PopupMenu.css';

const PopupMenu: FC<AppContext> = ({
  appId,
  applications,
  setApplications,
}) => {
  const [selected, setSelected] = useState<AppRecord>();
  //const [apps, setApps] = useState<AppRecord[]>(
  //  applications || ([] as AppRecord[]),
  //);
  useEffect(() => {
    //setApps(applications as AppRecord[]);
    if (applications) {
      applications.map((app: AppRecord) => {
        if (app.AppId === appId) {
          setSelected(app);
        }
        return app;
      });
    }
  }, [applications, appId]);
  const onMouseLeave: React.MouseEventHandler<HTMLDivElement> = (e) => {
    let newApps;
    if (applications) {
      newApps = applications.map((record: AppRecord) => {
        return { ...record, PopupOpened: false };
      });
      setApplications(newApps);
    }
  };
  /*
 e: React.MouseEvent<HTMLDivElement, MouseEvent>,
  status: number,
  appId: string,
  selected: AppRecord,
  setSelected: any,
  apps: AppRecord[],

*/
  const approveClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (selected && applications) {
      menuItemClicked(
        e,
        APP_APPROVED,
        selected,
        setSelected,
        applications,
        setApplications,
      );
    }
  };
  const unreadClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (selected && applications) {
      menuItemClicked(
        e,
        APP_PRESTINE,
        selected,
        setSelected,
        applications,
        setApplications,
      );
    }
  };

  const denyClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (selected && applications) {
      menuItemClicked(
        e,
        APP_DENIED,
        selected,
        setSelected,
        applications,
        setApplications,
      );
    }
  };

  const deleteClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    if (selected && applications) {
      menuItemClicked(
        e,
        APP_DELETED,
        selected,
        setSelected,
        applications,
        setApplications,
      );
    }
  };

  return (
    <div className='popup-menu' onMouseLeave={onMouseLeave}>
      {selected && selected.Status === APP_READ && (
        <div className='popup-menu-item' onClick={unreadClick}>
          Mark as unread.
        </div>
      )}
      {selected &&
        (selected.Status === APP_READ ||
          selected.Status === APP_PRESTINE ||
          selected.Status === APP_DENIED) && (
          <div className='popup-menu-item' onClick={approveClick}>
            Approve application.
          </div>
        )}
      {selected &&
        (selected.Status === APP_APPROVED ||
          selected.Status === APP_PRESTINE ||
          selected.Status === APP_READ) && (
          <div className='popup-menu-item' onClick={denyClick}>
            Deny application.
          </div>
        )}
      {selected &&
        (selected.Status === APP_READ || selected.Status === APP_PRESTINE) && (
          <div
            className='popup-menu-item popup-menu-item-last '
            onClick={deleteClick}>
            Delete.
          </div>
        )}
    </div>
  );
};

export default PopupMenu;
