import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { APPLICATIONS_TABLE } from '../../constants';

const Landing = () => {
  const history = useHistory();
  useEffect(() => {
    if (history) {
      history.push(APPLICATIONS_TABLE);
    }
  }, [history]);
  return <div></div>;
};

export default Landing;
