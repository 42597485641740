import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%;
  height: 100%;
  background-color: red;
  color: white;
  position: absolute;
  align-items: center;
  justify-content: center;
  font-size: 40px;
`;
const Unauthorized = () => <Wrapper>Unauthorized!!!</Wrapper>;

export default Unauthorized;
