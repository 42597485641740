export const ROOT_URL = 'https://app.dkassociates.us/api/';
export const GET_ALL_APPLICATIONS = 'getAllApplications';
export const GET_DOB = 'getDateOfBirth';
export const UPDATE_STATUS = 'updateStatus';
export const UPDATE_NOTES = 'updateNotes';
export const GET_LICENSE = 'getLicense';

// Routes
export const APPLICATIONS_TABLE = '/applicationTable';
export const REVIEW_APPLICATION = '/reviewApplication';
export const UNAUTHORIZED = '/Unauthorized';

export const DATA_KEY = 'data';

//Record status
export const APP_PRESTINE = 0;
export const APP_READ = 1;
export const APP_APPROVED = 2;
export const APP_DENIED = 3;
export const APP_DELETED = 4;
