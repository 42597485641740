import React, { FC, useEffect } from 'react';
import './PersonalData.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Field,
  Layout,
  SectionContainer,
  StaticField,
} from '../../common/common.style';
import { AppContext } from '../../../types';
import { DATA_KEY } from '../../../constants';

const PersonalData: FC<AppContext> = ({ selected, setSelected }) => {
  return (
    <SectionContainer>
      <div className='greenField sectionHeader'>Personal Data</div>
      {selected && selected.Json && (
        <Layout>
          <Field>
            <div>Full Name:</div>
            <div>
              <StaticField>{selected!.Json.FullName}</StaticField>
            </div>
          </Field>
          <Field>
            <div>Apartment address:</div>
            <div>
              <StaticField>{selected!.Json.ApartmentAddress}</StaticField>
            </div>
          </Field>
          <Field>
            <div>Phone number:</div>
            <div>
              <StaticField>{selected!.Json.PhoneNumber}</StaticField>
            </div>
          </Field>
          <Field>
            <div>Emergency Phone number:</div>
            <div>
              <StaticField>{selected!.Json.EmergencyPhone}</StaticField>
            </div>
          </Field>
          <Field>
            <div>Social Security Number:</div>
            <div>
              <StaticField>{selected!.Json.SSN}</StaticField>
            </div>
          </Field>

          <Field>
            <div>Date of birth:</div>
            <StaticField>{selected!.Json.DateOfBirth}</StaticField>
          </Field>

          <Field>
            <div>Driving License Number:</div>
            <div>
              <StaticField>{selected!.Json.DrivingLicenceNumber}</StaticField>
            </div>
          </Field>

          <Field>
            <div>Car Make and Model:</div>
            <div>
              <StaticField>{selected!.Json.CarMakeModel}</StaticField>
            </div>
          </Field>
          <Field>
            <div>Driving License State:</div>
            <div>
              <StaticField>{selected!.Json.LicenseState}</StaticField>
            </div>
          </Field>

          <Field>
            <div>Car License Plate:</div>
            <div>
              <StaticField>{selected!.Json.CarPlateNumber}</StaticField>
            </div>
          </Field>
          <Field>
            <div>Email:</div>
            <StaticField>{selected!.Json.Email}</StaticField>
          </Field>
        </Layout>
      )}
    </SectionContainer>
  );
};

export default PersonalData;
